import { combineReducers } from 'redux';

const byKey = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_RESTAURANT_DATA': {
      const newState = {};
      if (action.restaurantData) newState[action.restaurantData.url] = action.restaurantData;
      return { ...state, ...newState };
    }
    case 'FETCH_RESTAURANT_DATA_NOT_FOUND': {
      const newState = {};
      newState[action.restaurantData.url] = 'RESTAURANT_NOT_FOUND';
      return { ...state, ...newState };
    }
    default:
      return state;
  }
};

const restaurants = combineReducers({
  byKey
});

export default restaurants;

export const getRestaurant = (state, restaurantKey) => {
  return state && state.byKey && state.byKey[restaurantKey];
};
