import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_RESTAURANT_BASIC_INFO': {
      const newState = {};
      if (action.restaurantBasicInfo) newState[action.restaurantBasicInfo.userIdentity] = action.restaurantBasicInfo.businesses[0];

      return { ...state, ...newState };
    }
    default:
      return state;
  }
};

const restaurantBasicInfo = combineReducers({
  byId
});

export default restaurantBasicInfo;

export const getRestaurantBasicInfo = (state, userId) => {
  return state.byId[userId];
};

export const getDeliveryRates = (state, userId) => {
  const info = getRestaurantBasicInfo(state, userId);
  if (!info || !info.deliverySettings || !info.deliverySettings.deliveryRates) return [];
  else return info.deliverySettings.deliveryRates;
};

export const getDeliveryAdditionalRate = (state, userId) => {
  const info = getRestaurantBasicInfo(state, userId);
  if (!info || !info.deliverySettings || !info.deliverySettings.additionalRate) return 0;
  else return info.deliverySettings.additionalRate;
};

export const getGeoLocation = (state, userId) => {
  const info = getRestaurantBasicInfo(state, userId);
  if (!info || !info.onlineStoreSettings || !info.onlineStoreSettings.geoLocation2) return null;
  else return info.onlineStoreSettings.geoLocation2;
};

export const getDeliveryAreas = (state, userId) => {
  const info = getRestaurantBasicInfo(state, userId);
  if (!info || !info.deliverySettings || !info.deliverySettings.deliveryAreas) return null;
  else return info.deliverySettings.deliveryAreas;
};
