import { v4 as uuid4 } from 'uuid';

export const generateTitle = text => {
  const splitStr = text ? text.split(' ') : '';
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0) + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr ? splitStr.join(' ') : '';
};

export const formatPhoneNumber = phoneNumberString => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

// export const getItemRetailPriceList = itemDetails => {
//   let retailPriceList = null;
//   itemDetails.itemPrices && Object.keys(itemDetails.itemPrices).forEach(key => {
//     const priceList = itemDetails.itemPrices[key].priceList;
//     if (priceList && priceList.id === 'Retail') retailPriceList = priceList;
//   });
//   return retailPriceList;
// };

// export const getItemRetailPrice = itemDetails => {
//   let retailPrice = 0;
//   itemDetails && itemDetails.itemPrices && Object.keys(itemDetails.itemPrices).forEach(key => {
//     const priceList = itemDetails.itemPrices[key].priceList;

//     if (priceList && priceList.id === 'Retail') retailPrice = itemDetails.itemPrices[key].price;
//   });

//   return retailPrice;
// };

export const getItemOnlinePriceList = (itemDetails) => {
  let defaultPriceList = null;

  if (itemDetails && itemDetails.onlineStorePriceList) {
    itemDetails.itemPrices && Object.keys(itemDetails.itemPrices).forEach(key => {
      const priceList = itemDetails.itemPrices[key].priceList;
      const onlinePriceList = itemDetails.onlineStorePriceList;

      if (onlinePriceList && priceList && onlinePriceList.id === priceList.id) {
        defaultPriceList = priceList;
      }
    });
  } else {
    itemDetails.itemPrices && Object.keys(itemDetails.itemPrices).forEach(key => {
      const priceList = itemDetails.itemPrices[key].priceList;
      if (priceList && priceList.id === 'Retail') defaultPriceList = priceList;
    });
  }

  return defaultPriceList;
};

export const getItemOnlinePrice = (itemDetails) => {
  let defaultPrice = 0;

  if (itemDetails && itemDetails.onlineStorePriceList) {
    itemDetails && itemDetails.itemPrices && Object.keys(itemDetails.itemPrices).forEach(key => {
      const priceList = itemDetails.itemPrices[key].priceList;
      const onlinePriceList = itemDetails.onlineStorePriceList;

      if (onlinePriceList && priceList && onlinePriceList.id === priceList.id) {
        defaultPrice = itemDetails.itemPrices[key].price;
      }
    });
  } else {
    itemDetails && itemDetails.itemPrices && Object.keys(itemDetails.itemPrices).forEach(key => {
      const priceList = itemDetails.itemPrices[key].priceList;
      if (priceList && priceList.id === 'Retail') defaultPrice = itemDetails.itemPrices[key].price;
    });
  }

  return defaultPrice;
};

export const getDayString = dayOfWeek => {
  switch (dayOfWeek) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return 'Special Day';
  }
};

function currencySymbolMapper (code) {
  var symbol;
  if (code === 'LKR') {
    symbol = 'Rs';
  } else if (code === 'USD') {
    symbol = '$';
  } else if (code === 'EUR') {
    symbol = '€';
  } else if (code === 'GBP') {
    symbol = '£';
  } else if (code === 'JPY') {
    symbol = '¥';
  } else {
    symbol = code;
  }
  return symbol;
}

export const currencyFormat = (currency) => ({
  code: currency + '' || 'LKR ',
  symbol: currency && currencySymbolMapper(currency) ? currencySymbolMapper(currency) + ' ' : 'Rs ',
  decimalDigits: 5,
  decimalSeparator: '.'
});

export const getLineTotal = orderItem => getUnitPrice(orderItem) * orderItem.quantity;

export const getUnitPrice = orderItem => orderItem.price + orderItem.modifiers.reduce((accumulator, modifier) => accumulator + modifier.price * modifier.quantity, 0);

export const createDefaultOptionModifiers = item => {
  return item && item.ingredients.filter(ingredient => ingredient.hasOptions).map(optionGroup => createOptionModifier(optionGroup.id, optionGroup.options['0'].item));
};

export const createOrderModifier = (lineId, item) => {
  const priceList = getItemOnlinePriceList(item);
  const price = getItemOnlinePrice(item);
  return { id: uuid4(), lineId, code: item.code, name: item.name, item, selectedPriceList: priceList, price, quantity: 1, isOption: false };
};

export const createOptionModifier = (lineId, item) => {
  return { id: uuid4(), lineId, code: item.code, name: item.name, item, price: 0, quantity: 1, isOption: true };
};

export const getDeliveryCharge = (restaurantGeoLocation, consumerGeoLocation, rateInfos, additionalRate) => {
  const distance = calculateDistance(restaurantGeoLocation.lat, restaurantGeoLocation.lng, consumerGeoLocation.lat, consumerGeoLocation.lng, 'K');
  // Order delivery rates by "from" value accending order.
  // To be on the safe side when we picking up the correct rate.
  // console.log(distance + '--------------- in km');
  rateInfos.sort((r1, r2) => r1.from - r2.from);

  // Choose the correct delivery rate
  let rate = 0;
  let additionalDistanceRate = 0;

  if (rateInfos && rateInfos.length > 0) {
    rateInfos.forEach(info => {
      if (distance > info.from && distance <= info.to) {
        rate = Math.ceil(info.rate * 100) / 100;
      } else if (distance > info.to) {
        additionalDistanceRate = Math.ceil(distance - info.to) * additionalRate;
        rate = Math.ceil(info.rate * 100) / 100 + additionalDistanceRate;
      }
    });
  } else {
    additionalDistanceRate = Math.ceil(distance) * additionalRate;
    rate = additionalDistanceRate;
  }

  return rate;
};

export const isLocationCovered = (consumerGeoLocation, deliveryAreas) => {
  if (deliveryAreas) {
    for (let i = 0; i < deliveryAreas.length; i++) {
      const area = deliveryAreas[i];
      const distance = calculateDistance(consumerGeoLocation.lat, consumerGeoLocation.lng, area.geoLocation2.lat, area.geoLocation2.lng, 'K');
      if (distance < area.radius) {
        return true;
      }
    }
  }
  return false;
};

// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// :::                                                                         :::
// :::  This routine calculates the distance between two points (given the     :::
// :::  latitude/longitude of those points). It is being used to calculate     :::
// :::  the distance between two locations using GeoDataSource (TM) prodducts  :::
// :::                                                                         :::
// :::  Definitions:                                                           :::
// :::    South latitudes are negative, east longitudes are positive           :::
// :::                                                                         :::
// :::  Passed to function:                                                    :::
// :::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
// :::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
// :::    unit = the unit you desire for results                               :::
// :::           where: 'M' is statute miles (default)                         :::
// :::                  'K' is kilometers                                      :::
// :::                  'N' is nautical miles                                  :::
// :::                                                                         :::
// :::  Worldwide cities and other features databases with latitude longitude  :::
// :::  are available at https://www.geodatasource.com                         :::
// :::                                                                         :::
// :::  For enquiries, please contact sales@geodatasource.com                  :::
// :::                                                                         :::
// :::  Official Web site: https://www.geodatasource.com                       :::
// :::                                                                         :::
// :::               GeoDataSource.com (C) All Rights Reserved 2018            :::
// :::                                                                         :::
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

function calculateDistance (lat1, lon1, lat2, lon2, unit) {
  if ((lat1 === lat2) && (lon1 === lon2)) {
    return 0;
  } else {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') { dist = dist * 1.609344; }
    if (unit === 'N') { dist = dist * 0.8684; }
    return dist;
  }
}
