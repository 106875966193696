const modal = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case 'CLEAR_RESTAURANT_DATA': {
      return {};
    }
    case 'UPDATE_MODAL': {
      const { modal } = action;
      return { ...state, modal };
    }
    default:
      return state;
  }
};

export default modal;
// export const get = (state, restaurantKey) => state.byRestaurantKey[restaurantKey] || { ...defaultCart };
export const get = (state) => state.modal || { ...defaultUI };

const defaultUI = {
  modal: {
    visibleAccountDeleteVerificationModal: false,
    visibleChangePasswordModal: false,
    visibleConsumerFeedbackModal: false
  }
};
