import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  const { type, order, orderId } = action;
  switch (type) {
    case 'ADD_ORDER':
    case 'UPDATE_ORDER':
      return { ...state, [order.id]: order };
    case 'UPDATE_CLIENT_PAYMENT_STATUS': {
      const order = state[orderId];
      if (!order) return state;

      const newOrder = { ...order, clientPaymentStatus: action.status };
      return { ...state, [orderId]: newOrder };
    }
    case 'FETCH_CONSUMER_ORDERS': {
      const newState = { ...state };
      action.orderData.forEach(data => {
        if (data && data.order) {
          newState[data.order.id] = data.order;
          newState[data.order.id].restaurantData = data.order.restaurant;
        }
      });

      return newState;
    }
    case 'LOGOUT':
      return {};
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  const { type, order } = action;

  switch (type) {
    case 'ADD_ORDER': {
      const { id } = order;
      if (!state.includes(id)) {
        return [...state, id];
      } else {
        return state;
      }
    }
    case 'FETCH_CONSUMER_ORDERS': {
      const newState = [...state];
      action.orderData.forEach(data => {
        if (data.order && !state.includes(data.order.id)) {
          newState.push(data.order.id);
        }
      });

      return newState;
    }
    case 'LOGOUT':
      return [];
    default:
      return state;
  }
};

const byRestaurantKey = (state = {}, action) => {
  const { type, restaurantKey, order } = action;

  switch (type) {
    case 'ADD_ORDER': {
      const { id } = order;
      const orderIds = state[restaurantKey] || [];
      if (!orderIds.includes(id)) {
        return [...orderIds, id];
      } else {
        return orderIds;
      }
    }
    default:
      return state;
  }
};

const orders = combineReducers({
  byId,
  ids,
  byRestaurantKey
});

export default orders;

export const get = (state, id) => state.byId[id];
export const getAll = (state) => state.ids.map(id => state.byId[id]).filter(order => !!order);
