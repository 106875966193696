import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_COMPANY_CATEGORIES': {
      const newState = { ...state };
      action.companyCategories.forEach(companyCategory => {
        newState[companyCategory.id] = companyCategory;
      });
      return newState;
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_COMPANY_CATEGORIES': {
      const newState = [...state];
      action.companyCategories.forEach(companyCategory => {
        if (!state.includes(companyCategory.id)) {
          newState.push(companyCategory.id);
        }
      });

      return newState;
    }
    default:
      return state;
  }
};

const companyCategories = combineReducers({
  byId,
  ids
});

export default companyCategories;

export const getCompanyCategories = (state) => state.ids.map(id => state.byId[id]);
