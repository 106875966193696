import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'SUCCEED_FETCH_ITEMS':
    case 'SUCCEED_FETCH_ITEMS_FOR_CATEGORY': {
      const newState = { ...state };
      const { items, variants } = action;
      items.forEach(item => { newState[item.id] = item; });
      variants && variants.length > 0 && variants.forEach(variants => { newState[variants.id] = variants; });
      return newState;
    }
    case 'CLEAR_RESTAURANT_DATA': {
      return {};
    }
    case 'FETCH_ITEM': {
      return { ...state, [action.item.id]: action.item };
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'CLEAR_RESTAURANT_DATA': {
      return [];
    }
    case 'SUCCEED_FETCH_ITEMS': {
      const newState = [];
      const { items } = action;
      items && items.length > 0 && items.forEach(item => {
        if (!newState.includes(item.id)) {
          newState.push(item.id);
        }
      });
      return newState;
    }

    // case 'SUCCEED_FETCH_ITEMS_FOR_CATEGORY': {
    //   const newState = [...state];
    //   const { items } = action;
    //   items && items.length > 0 && items.forEach(item => {
    //     if (!newState.includes(item.id)) {
    //       newState.push(item.id);
    //     }
    //   });
    //   return newState;
    // }

    case 'FETCH_ITEM': {
      if (state.includes(action.item.id)) {
        return state;
      } else {
        return [...state, action.item.id];
      }
    }
    default:
      return state;
  }
};

const byCategory = (state = {}, action) => {
  switch (action.type) {
    case 'CLEAR_RESTAURANT_DATA': {
      return {};
    }
    case 'SUCCEED_FETCH_ITEMS_FOR_CATEGORY': {
      const { items, categoryId } = action;
      return { ...state, [categoryId]: items.map(item => item.id) };
    }
    default:
      return state;
  }
};

const byVariantGroupId = (state = {}, action) => {
  switch (action.type) {
    case 'CLEAR_RESTAURANT_DATA': {
      return {};
    }
    case 'SUCCEED_FETCH_ITEMS_FOR_CATEGORY': {
      const { items } = action;
      const newState = {};
      items.forEach(variantItem => {
        const groupId = variantItem.variant_definition_id;
        if (groupId) {
          const ids = newState[groupId] || [];
          if (!ids.includes(variantItem.id)) {
            ids.push(variantItem.id);
          }
          newState[groupId] = ids;
        }
      });

      return { ...state, ...newState };
    }
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case 'CLEAR_RESTAURANT_DATA': {
      return false;
    }
    case 'START_FETCH_ITEMS':
      return true;
    case 'SUCCEED_FETCH_ITEMS':
    case 'FAIL_FETCH_ITEMS':
      return false;
    default:
      return state;
  }
};

const fetchingForCategory = (state = {}, action) => {
  switch (action.type) {
    case 'CLEAR_RESTAURANT_DATA': {
      return {};
    }
    case 'START_FETCH_ITEMS_FOR_CATEGORY':
      return { ...state, [action.categoryId]: true };
    case 'SUCCEED_FETCH_ITEMS_FOR_CATEGORY':
    case 'FAIL_FETCH_ITEMS_FOR_CATEGORY':
      return { ...state, [action.categoryId]: false };
    default:
      return state;
  }
};

const errorForCategory = (state = {}, action) => {
  switch (action.type) {
    case 'CLEAR_RESTAURANT_DATA': {
      return {};
    }
    case 'START_FETCH_ITEMS_FOR_CATEGORY':
      return { ...state, [action.categoryId]: null };
    case 'FAIL_FETCH_ITEMS_FOR_CATEGORY':
      return { ...state, [action.categoryId]: action.error };
    default:
      return state;
  }
};

const items = combineReducers({
  byId,
  ids,
  byCategory,
  fetching,
  fetchingForCategory,
  errorForCategory,
  byVariantGroupId
});

export default items;

export const getItemsByIsIngredient = state => {
  const items = state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
  return items.filter(item => item.isIngredient);
};

export const getAllItems = (state) => {
  return (state && state.byId && Object.values(state.byId).length > 0 ? Object.values(state.byId).map(byId => byId) : []);
};

export const getItems = (state, itemIds = []) => {
  return itemIds.map(id => state.byId[id]).filter(item => !!item).filter(item => item.displayInOnlineMenu2);
};

export const getItem = (state, itemId) => {
  return state && state.byId && state.byId[itemId];
};

export const isFetching = state => state.fetching;

export const isFetchingForCategory = (state, categoryId) => state.fetchingForCategory[categoryId];

export const getErrorForCategory = (state, categoryId) => state.errorForCategory[categoryId];

export const getItemsByVariantGroupId = (state, groupId) => {
  return (state.byVariantGroupId[groupId] || []).map(id => state.byId[id]);
};
