import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Auth from './auth';
import Root from './Root';
import store from './store';
import './App.css';
import './incyStyles.css';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

const auth = new Auth(store);
const breakpoints = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1365,
      xl: 1920
    }
  }
});

class App extends Component {
  state = {
    screenType: window.innerWidth < 600 ? 'MOBILE' : 'DESKTOP'
  }

  componentDidMount () {
    window.addEventListener('resize', () => this.getView(), { passive: true });
  }

  componentWillUnmount () {
    window.removeEventListener('resize', () => this.getView());
  }

  getView = () => {
    const width = window.innerWidth;
    this.setState({ screenType: width < 600 ? 'MOBILE' : 'DESKTOP' });
  }

  getChildContext () {
    return { auth, screenType: this.state.screenType };
  }

  render () {
    const theme = breakpoints;
    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <BrowserRouter>
            <Root auth={auth} />
          </BrowserRouter>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

App.childContextTypes = {
  auth: PropTypes.object.isRequired,
  screenType: PropTypes.object.isRequired
};

export default App;
