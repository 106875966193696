import { combineReducers } from 'redux';
import auth, * as fromAuth from './auth';
import categories, * as fromCategories from './categories';
import items, * as fromItems from './items';
import restaurants, * as fromRestaurants from './basicData';
import carts, * as fromCarts from './carts';
import orders, * as fromOrders from './orders';
import restaurantInfo, * as fromRestaurantInfo from './basicInfo';
import companies, * as fromCompanies from './companies';
import companyCategories, * as fromCompanyCategories from './companyCategories';
import filters, * as fromFilters from './filters';
import ui, * as fromUis from './ui';
import activeRestaurant, * as fromActiveRestaurant from './activeRestaurant';
import validatedCarts, * as fromValidatedCarts from './validatedCart';
const rootReducer = combineReducers({
  auth,
  categories,
  items,
  restaurants,
  carts,
  orders,
  restaurantInfo,
  companies,
  companyCategories,
  filters,
  ui,
  activeRestaurant,
  validatedCarts
});

export default rootReducer;

// ActiveRestaurant
export const getActiveRestaurant = (state) => fromActiveRestaurant.get(state.activeRestaurant);

// Auth user
export const isAuthenticated = state => fromAuth.isAuthenticated(state.auth);
export const getAuthData = state => fromAuth.getAuthData(state.auth);
export const getLoggedInUser = state => fromAuth.getUser(state.auth);

// Categories
export const getCategory = (state, categoryId) => fromCategories.getCategory(state.categories, categoryId);
export const getCategoryTree = state => fromCategories.getCategoryTree(state.categories);
export const getFirstCategoryId = state => fromCategories.getFirstCategoryId(state.categories);
export const getCategoryTreeByCategoryId = (state, parentId) => fromCategories.getCategoryTreeByCategoryId(state.categories, parentId);
export const getTopCategoryDataByCategoryId = (state, categoryId) => fromCategories.getTopCategoryDataByCategoryId(state.categories, categoryId);

// Items
export const getAllItems = state => fromItems.getAllItems(state.items);
export const getItem = (state, itemId) => fromItems.getItem(state.items, itemId);
export const getItemsByCategory = (state, parentId) => {
  const category = getCategory(state, parentId);
  if (!category) return [];
  return fromItems.getItems(state.items, category.itemIds);
};
export const isItemsFetchingForCategory = (state, parentId) => fromItems.isFetchingForCategory(state.items, parentId);
export const getItemsErrorForCategory = (state, parentId) => fromItems.getErrorForCategory(state.items, parentId);
export const getItemsByIsIngredient = state => fromItems.getItemsByIsIngredient(state.items);
export const isItemsFetching = state => fromItems.isFetching(state.items);
export const getItemsByVariantGroupId = (state, groupId) => fromItems.getItemsByVariantGroupId(state.items, groupId);

// BasicData
export const getRestaurantData = (state, restaurantKey) => fromRestaurants.getRestaurant(state.restaurants, restaurantKey);

// Carts
export const getCart = (state, restaurantKey) => fromCarts.get(state.carts, restaurantKey);
export const getCartSubtotal = (state, restaurantKey) => fromCarts.getSubtotal(state.carts, restaurantKey);
export const getCartItems = (state, restaurantKey) => fromCarts.getItems(state.carts, restaurantKey);
export const getCartItemCount = (state, restaurantKey) => fromCarts.getItemCount(state.carts, restaurantKey);
export const getCartOrderType = (state, restaurantKey) => fromCarts.getOrderType(state.carts, restaurantKey);
export const getOrderItem = (state, restaurantKey, orderItemId) => fromCarts.getOrderItem(state.carts, restaurantKey, orderItemId);

// validated carts
export const getValidateCart = (state, restaurantKey) => fromValidatedCarts.get(state.validatedCarts, restaurantKey);

// Orders
export const getOrder = (state, id) => fromOrders.get(state.orders, id);
export const getOrders = (state) => fromOrders.getAll(state.orders);

// Restaurant Info
export const getBasicRestaurantInfo = (state, userId) => fromRestaurantInfo.getRestaurantBasicInfo(state.restaurantInfo, userId);
export const getDeliveryRates = (state, restaurantKey) => {
  const restaurant = fromRestaurants.getRestaurant(state.restaurants, restaurantKey);
  if (!restaurant) return [];
  return fromRestaurantInfo.getDeliveryRates(state.restaurantInfo, restaurant.userId);
};

export const getDeliveryAdditionalRate = (state, restaurantKey) => {
  const restaurant = fromRestaurants.getRestaurant(state.restaurants, restaurantKey);
  if (!restaurant) return 0;
  return fromRestaurantInfo.getDeliveryAdditionalRate(state.restaurantInfo, restaurant.userId);
};

export const getRestaurantGeoLocation = (state, restaurantKey) => {
  const restaurant = fromRestaurants.getRestaurant(state.restaurants, restaurantKey);
  if (!restaurant) return null;
  return fromRestaurantInfo.getGeoLocation(state.restaurantInfo, restaurant.userId);
};
export const getRestaurantDeliveryAreas = (state, restaurantKey) => {
  const restaurant = fromRestaurants.getRestaurant(state.restaurants, restaurantKey);
  if (!restaurant) return [];
  return fromRestaurantInfo.getDeliveryAreas(state.restaurantInfo, restaurant.userId);
};
export const getOnlineCompanies = (state) => fromCompanies.getOnlineCompanies(state.companies);
export const getCompanyCategories = (state) => fromCompanyCategories.getCompanyCategories(state.companyCategories);

// filters
export const getfilter = (state, restaurantKey) => fromFilters.get(state.filters, restaurantKey);

// ui
export const getModal = (state) => fromUis.get(state.ui);
