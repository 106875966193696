import { combineReducers } from 'redux';

const name = (state = {}, action) => {
  const { type, restaurantKey } = action;
  switch (type) {
    case 'ADD': {
      return restaurantKey;
    }
    case 'CLEAR_RESTAURANT_DATA':
      return '';
    default:
      return state;
  }
};

const activeRestaurants = combineReducers({
  name
});

export default activeRestaurants;
export const get = (state) => state.name ? state.name : '';
