import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import logo from '../../images/logo.svg';
import PropTypes from 'prop-types';

class Loader extends Component {
  render () {
    const { isSidebar } = this.props;
    const isDesktop = this.context.screenType === 'DESKTOP';

    return (
      <div style={{ ...styles.loaderContainer, width: (isSidebar && isDesktop) ? 400 : '100vw', right: (isSidebar && isDesktop) && 0 }}>
        <img src={logo} alt='Main Logo' width={160} style={{ marginBottom: 20 }} />
        <CircularProgress color='primary' />
      </div>
    );
  }
}

const styles = {
  loaderContainer: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    zIndex: 10500,
    top: 0
  }
};

Loader.contextTypes = {
  screenType: PropTypes.object.isRequired
};

export default Loader;
