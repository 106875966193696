import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'CLEAR_RESTAURANT_DATA': {
      return {};
    }
    case 'FETCH_ONLINE_COMPANIES': {
      const newState = {};
      action.companies.forEach(company => {
        newState[company.id] = company;
      });
      return newState;
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'CLEAR_RESTAURANT_DATA': {
      return [];
    }
    case 'FETCH_ONLINE_COMPANIES': {
      const newState = [];
      action.companies.forEach(company => {
        newState.push(company.id);
      });
      return newState;
    }
    default:
      return state;
  }
};

const companies = combineReducers({
  byId,
  ids
});

export default companies;

export const getOnlineCompanies = (state) => state.ids.map(id => state.byId[id]);
