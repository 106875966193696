import { FiInfo } from 'react-icons/all';
import { Modal } from 'antd';
import React from 'react';
import auth0 from 'auth0-js';
const { confirm } = Modal;

class Auth {
  accessToken;
  idToken;
  expiresAt;

  auth0 = new auth0.WebAuth({
    domain: 'fooder.auth0.com',
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: window.location.protocol + '//' + window.location.host + '/callback',
    audience: 'https://fooder.auth0.com/userinfo',
    responseType: 'token id_token',
    scope: 'openid profile email'
  });

  constructor (reduxStore) {
    this.reduxStore = reduxStore;
    this.restoreSession(reduxStore.getState().auth.authData);
  }

  login = () => {
    this.reduxStore.dispatch({ type: 'LOGOUT' });
    this.auth0.authorize();
  }

  showInformationAlert = (message, type) => {
    confirm({
      title: message,
      content: '',
      icon: <FiInfo size={25} style={{ color: '#ff4d25', float: 'left', marginRight: 14 }} />,
      onOk () {},
      onCancel () {},
      cancelButtonProps: { style: { display: 'none' } },
      okButtonProps: { style: { backgroundColor: '#498d05', borderColor: '#498d05' } }
    });
  }

  handleAuthentication = callback => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        console.log(authResult);
        this.setSession(authResult, callback);
      } else if (err) {
        console.log(err);
        this.showInformationAlert('An error occured while authenticating. Please try again.', '');
        callback(err);
      }
    });
  }

  handleDeleteUser = user => {
    return this.deleteConsumer(user.id);
  }

  deleteConsumer = (userId) => {
    const hostname = window.location.hostname;
    const host = hostname === 'localhost' ? hostname + ':8082' : hostname;
    const server = window.location.protocol + '//' + host;
    const url = server + '/api/consumers/' + userId;

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    return window.fetch(url, { method: 'DELETE', headers })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.success) return true;

        return false;
      }).catch(error => {
        console.log('ERROR ', error);
        return false;
      });
  }

  parseJwt = token => {
    var base64Url = token.split('.')[1];
    var base64 = decodeURIComponent(window.atob(base64Url).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(base64);
  };

  getAccessToken = () => {
    return this.accessToken;
  }

  getIdToken = () => {
    return this.idToken;
  }

  setSession = (authResult, callback) => {
    // Set the time that the Access Token will expire at
    const expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
    const accessToken = authResult.accessToken;
    const idToken = authResult.idToken;

    this.expiresAt = expiresAt;
    this.accessToken = accessToken;
    this.idToken = idToken;

    const profileData = authResult.idTokenPayload;

    const name = profileData.name === profileData.email ? '' : profileData.name;
    const user = {
      _id: profileData.sub,
      email: profileData.email,
      emailVerified: profileData.email_verified,
      name: name,
      profilePicture: profileData.picture
    };
    console.log(user);
    this.addUser(user, callback);
  }

  addUser = (user, callback) => {
    const hostname = window.location.hostname;
    const host = hostname === 'localhost' ? hostname + ':8082' : hostname;
    const server = window.location.protocol + '//' + host;
    const url = server + '/api/consumers';

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    const body = JSON.stringify(this.getNewConsumer(user));

    window.fetch(url, { method: 'POST', headers, body })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.success) {
          this.reduxStore.dispatch({
            type: 'SET_LOGGED_IN_USER',
            user: { ...user, consumer: responseJson.data },
            authData: {
              accessToken: this.accessToken,
              idToken: this.idToken,
              expiresAt: this.expiresAt
            }
          });
          callback();
        } else {
          console.log(responseJson);
          if (responseJson.error && responseJson.error.data) {
            this.reduxStore.dispatch({
              type: 'SET_LOGGED_IN_USER',
              user: { ...user, consumer: responseJson.error.data },
              authData: {
                accessToken: this.accessToken,
                idToken: this.idToken,
                expiresAt: this.expiresAt
              }
            });
          } else {
            this.logout();
            console.log('ERROR ', responseJson.error);
            callback(responseJson.error.message);
          }
        }
      }).catch(error => {
        this.logout();
        console.log('ERROR ', error);
        callback(error);
      });
  }

  getNewConsumer = (user) => {
    const deliveryDetails = [{
      id: undefined,
      address: '',
      city: undefined,
      postalCode: undefined,
      country: undefined,
      geoLocation2: undefined,
      remarks: undefined,
      defaultAddress: true
    }];

    return {
      authId: user._id,
      name: user.name || '',
      email: user.email || '',
      profilePicture: user.profilePicture,
      phoneNumber: '',
      deliveryDetails,
      remarks: undefined,
      mobileVerified: false
    };
  }

  restoreSession = authData => {
    const { expiresAt, accessToken, idToken } = authData;

    this.expiresAt = expiresAt;
    this.accessToken = accessToken;
    this.idToken = idToken;
  }

  renewSession = () => {
    if (!this.isAuthenticated()) {
      this.auth0.checkSession({}, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
        } else if (err) {
          this.logout();
          console.log(err);
        }
      });
    }
  }

  logout = (pathName = '') => {
    // if (!pathName) {
    //   const state = loadState() || {};
    //   const { name } = state.activeRestaurant || {};
    //   if (name) {
    //     saveRedirectAddress('/' + name);
    //   }
    // }

    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    // Remove isLoggedIn flag from localStorage
    this.reduxStore.dispatch({ type: 'LOGOUT' });

    // Let the Redux do its job.
    setTimeout(() => {
      this.auth0.logout({ returnTo: window.location.origin });
    }, 1000);
  }

  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = this.expiresAt;
    return new Date().getTime() < expiresAt;
  }

  isLoggedIn = () => !!this.accessToken;
}

export default Auth;
