export const loadState = () => load('state');

export const saveState = state => save('state', state);

export const loadRedirectAddress = () => load('redirectAddress');

export const saveRedirectAddress = address => save('redirectAddress', address);

const load = key => {
  try {
    const serializedState = window.localStorage.getItem(key);
    return serializedState && serializedState !== null
      ? JSON.parse(serializedState)
      : undefined;
  } catch (e) {
    return undefined;
  }
};

const save = (key, value) => {
  try {
    const serializedState = JSON.stringify(value);
    window.localStorage.setItem(key, serializedState);
  } catch (e) {
    console.error('Could not save ' + key + '.', e);
  }
};
