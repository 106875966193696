import { combineReducers } from 'redux';

const authData = (state = {}, action) => {
  switch (action.type) {
    case 'SET_LOGGED_IN_USER':
      return action.authData;

    case 'LOGOUT': {
      return {};
    }
    default:
      return state;
  }
};

const user = (state = {}, action) => {
  switch (action.type) {
    case 'SET_LOGGED_IN_USER':
      return action.user;

    case 'FETCH_CONSUMER': {
      const newState = { ...state, consumer: action.consumer };
      return newState;
    }

    case 'LOGOUT': {
      return {};
    }

    default:
      return state;
  }
};

export default combineReducers({
  authData,
  user
});

export const isAuthenticated = state => {
  console.log('auth data', state.authData && state.authData.expiresAt && state.authData.expiresAt > new Date().getTime());
  return state.authData && state.authData.expiresAt && state.authData.expiresAt > new Date().getTime();
};

export const getAuthData = state => state.authData;

export const getUser = state => state.user;
