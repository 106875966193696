import { combineReducers } from 'redux';
import { getItemOnlinePrice, getLineTotal } from '../../Common';

const byRestaurantKey = (state = {}, action) => {
  const { type, restaurantKey, item, quantity, orderId, orderItemId } = action;
  switch (type) {
    case 'ADD_TO_CART': {
      const { modifiers } = action;
      const cart = state[restaurantKey];
      const newCart = cart ? newCartState(cart) : { ...defaultCart };
      const orderItem = createNewCartItem(item, quantity, modifiers, orderItemId);

      newCart.items.push(orderItem);
      updateTotals(newCart);

      return { ...state, [restaurantKey]: newCart };
    }
    case 'CLEAR_CART':
      return { ...state, [restaurantKey]: { ...defaultCart } };
    case 'UPDATE_QUANTITY': {
      const cart = state[restaurantKey];
      if (!cart) return state;

      const { change } = action;
      const newCart = newCartState(cart);
      updateItemQuantity(newCart, orderItemId, change);

      return { ...state, [restaurantKey]: newCart };
    }
    case 'UPDATE_CART': {
      const cart = state[restaurantKey];
      if (!cart) return state;
      return { ...state, [restaurantKey]: { ...cart, ...action.cart } };
    }
    case 'UPDATE_CLIENT_PAYMENT_STATUS': {
      const cart = state[restaurantKey];
      if (cart.id !== orderId) return state;

      return { ...state, [restaurantKey]: { ...cart, clientPaymentStatus: action.status } };
    }
    case 'UPDATE_ORDER_TYPE': {
      const cart = state[restaurantKey];
      const newCart = { ...cart, type: action.orderType };
      updateTotals(newCart);
      return { ...state, [restaurantKey]: newCart };
    }

    case 'UPDATE_ORDER_REMARKS': {
      const cart = state[restaurantKey];
      const newCart = { ...cart, remarks: action.remarks };
      return { ...state, [restaurantKey]: newCart };
    }

    case 'UPDATE_CART_ITEM': {
      const { restaurantKey, modifiers, item, quantity, orderItemId } = action;
      const cart = state[restaurantKey];
      const orderItem = createNewCartItem(item, quantity, modifiers, orderItemId);
      const newCart = newCartState(cart);
      const cartItems = newCart && newCart.items && newCart.items.filter(item => !(item.id === orderItemId));
      newCart.items = cartItems;
      newCart.items.push(orderItem);
      console.log(modifiers);
      updateModifiers(newCart, orderItem.id, modifiers);
      updateTotals(newCart);
      console.log(newCart);
      return { ...state, [restaurantKey]: newCart };
    }

    case 'SET_LOGGED_IN_USER': {
      // Clear carts non-related to this user
      const newState = {};

      Object.keys(state).forEach(key => {
        const cart = state[key];
        let newCart = { ...cart };
        if (cart.customer && cart.customer.consumerId !== action.user.consumer.id) {
          newCart = { ...defaultCart };
        }
        newState[key] = newCart;
      });

      return newState;
    }
    case 'LOGOUT': {
      console.log('cleared cart');
      // Clear carts related to specific users
      const newState = {};

      Object.keys(state).forEach(key => {
        const cart = state[key];
        let newCart = { ...cart };
        if (cart.customer) {
          newCart = { ...defaultCart };
        }
        newState[key] = newCart;
      });

      return newState;
    }
    case 'UPDATE_MODIFIRES': {
      const cart = state[restaurantKey];
      if (!cart) return state;

      const { modifiers } = action;
      const newCart = newCartState(cart);
      updateModifiers(newCart, orderItemId, modifiers);

      return { ...state, [restaurantKey]: newCart };
    }
    case 'SET_DELIVERY_DETAIL': {
      const cart = state[restaurantKey];
      if (!cart) return state;

      const newCart = newCartState(cart);
      newCart.deliveryDetailId = action.detailId;
      newCart.deliveryCharge = action.charge;
      updateTotals(newCart);

      return { ...state, [restaurantKey]: newCart };
    }
    case 'UPDATE_VARIANT': {
      const { item, orderItemId, modifiers } = action;
      console.log(item, orderItemId, modifiers);
      const cart = state[restaurantKey];
      const newCart = cart ? newCartState(cart) : { ...defaultCart };

      const orderItemIndex = newCart.items.findIndex(cartItem => cartItem.id === orderItemId);
      const price = getItemOnlinePrice(item);

      newCart.items[orderItemIndex].item = item;
      newCart.items[orderItemIndex].price = price;
      updateTotals(newCart);
      updateModifiers(newCart, orderItemId, modifiers);

      return { ...state, [restaurantKey]: newCart };
    }
    default:
      return state;
  }
};

const carts = combineReducers({
  byRestaurantKey
});

export default carts;

export const get = (state, restaurantKey) => state.byRestaurantKey[restaurantKey] || { ...defaultCart };
export const getSubtotal = (state, restaurantKey) => get(state, restaurantKey).subtotal;
export const getItems = (state, restaurantKey) => get(state, restaurantKey).items;
export const getItemCount = (state, restaurantKey) => getItems(state, restaurantKey).length;
export const getOrderType = (state, restaurantKey) => state.byRestaurantKey[restaurantKey].type;
export const getOrderItem = (state, restaurantKey, orderItemId) => {
  const items = getItems(state, restaurantKey);
  return (items && items.length > 0) ? items.find(item => item.id === orderItemId) : null;
};

const defaultCart = {
  items: [],
  total: 0.0,
  type: 'TO_GO',
  remarks: ''
};

const newCartState = state => {
  return { ...state, items: [...state.items] };
};

const createNewCartItem = (item, quantity, modifiers, orderItemId) => {
  const price = getItemOnlinePrice(item);
  return { id: orderItemId, item, price, quantity, modifiers };
};

const updateItemQuantity = (cart, orderItemId, change) => {
  const { items, total } = cart;

  let itemIndex = -1;
  items.forEach((item, index) => {
    if (item.id === orderItemId) itemIndex = index;
  });

  if (itemIndex === -1) return;

  const item = items[itemIndex];
  const currentLineTotal = getLineTotal(item);
  const { quantity } = item;
  const newQuanaity = quantity + change;

  if (newQuanaity < 1) {
    cart.items.splice(itemIndex, 1);
    cart.total = total - currentLineTotal;
  } else {
    item.quantity = quantity + change;
    const newLineTotal = getLineTotal(item);
    cart.total = total - currentLineTotal + newLineTotal;
  }

  updateTotals(cart);
};

const updateModifiers = (cart, orderItemId, updatedModifiers) => {
  const { items, total } = cart;

  let itemIndex = -1;
  items.forEach((item, index) => {
    if (item.id === orderItemId) itemIndex = index;
  });

  if (itemIndex === -1) return;

  const item = items[itemIndex];
  const currentLineTotal = getLineTotal(item);

  item.modifiers = updatedModifiers;
  const newLineTotal = getLineTotal(item);

  cart.total = total - currentLineTotal + newLineTotal;
  items[itemIndex] = item;

  cart.items = items;
  updateTotals(cart);
};

const updateTotals = cart => {
  let subtotal = 0;
  cart.items.forEach(item => {
    subtotal += item.price * item.quantity;
    item.modifiers.forEach(modifier => {
      subtotal += modifier.price * modifier.quantity * item.quantity;
    });
  });
  cart.subtotal = subtotal;

  cart.total = cart.subtotal + cart.deliveryCharge;
};
