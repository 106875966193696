import { combineReducers } from 'redux';

const byId = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_CATEGORIES': {
      const newState = {};
      action.categories && action.categories.length > 0 && action.categories.forEach(category => { newState[category.id] = { ...category, loading: false }; });

      return { ...state, ...newState };
    }
    case 'FETCH_CATEGORY': {
      const { category } = action;
      category.items.forEach(item => {
        item.categoryId = category.id;
      });
      category.categories.forEach(child => {
        child.categoryId = category.id;
      });
      return { ...state, [category.id]: { ...category, loading: false } };
    }
    case 'REQUEST_CATEGORY': {
      const { categoryId } = action;
      const category = { ...state[categoryId], loading: true } || { loading: false };
      return { ...state, [categoryId]: category };
    }

    case 'CLEAR_RESTAURANT_DATA': {
      return {};
    }

    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case 'CLEAR_RESTAURANT_DATA': {
      return [];
    }
    case 'FETCH_CATEGORIES': {
      const newState = [...state];
      action.categories && action.categories.length > 0 && action.categories.forEach(category => {
        if (!newState.includes(category.id)) {
          newState.push(category.id);
        }
      });
      return newState;
    }
    case 'FETCH_CATEGORY':
      if (state.includes(action.category.id)) {
        return state;
      } else {
        return [...state, action.category.id];
      }
    default:
      return state;
  }
};

const byParentId = (state = {}, action) => {
  switch (action.type) {
    case 'CLEAR_RESTAURANT_DATA': {
      return {};
    }
    case 'FETCH_CATEGORY': {
      const { id, categories } = action.category;
      const newState = { ...state };
      newState[id] = categories && categories.length > 0 ? categories.map(category => category.id) : [];

      return newState;
    }
    default:
      return state;
  }
};

const categories = combineReducers({
  byId,
  ids,
  byParentId
});

export default categories;

export const getCategories = state => {
  return state && state.ids && state.ids.length > 0 ? state.ids.map(id => state.byId[id]) : [];
};

export const getCategoriesByParent = (state, parentId) => {
  const categoryIds = state.byParentId[parentId];
  return categoryIds && categoryIds.length > 0 ? categoryIds.map(id => state.byId[id]) : [];
};

export const getCategoryTree = (state, categoryId = 'defaultMenu') => {
  const category = state.byId[categoryId];

  if (!category || !category.categories) {
    return [];
  }

  const categoryList = [];
  Object.keys(category.categories).forEach(key => {
    categoryList.push(category.categories[key]);
  });
  return categoryList;
};

export const getCategory = (state, categoryId) => {
  return state && state.byId && state.byId[categoryId];
};

export const getFirstCategoryId = (state) => {
  const defaultMenu = 'defaultMenu';
  const defaultCategory = state.byId[defaultMenu];
  const defaultCategorySubCategories = defaultCategory && defaultCategory.categories && Object.values(defaultCategory.categories);
  if (defaultCategorySubCategories && defaultCategorySubCategories.length > 0) {
    return state && state.ids && state.ids.length > 0 && defaultCategorySubCategories[0].id;
  } else {
    return state && state.ids && state.ids.length > 0 && state.ids[1];
  }
};

export const getCategoryTreeByCategoryId = (state, categoryId) => {
  const category = state.byId[categoryId];

  if (!category || !category.categories) {
    return [];
  }

  const categoryList = [];
  Object.keys(category.categories).forEach(key => {
    categoryList.push(category.categories[key]);
  });
  return categoryList;
};

export const getTopCategoryDataByCategoryId = (state, categoryId) => {
  const defaultMenu = 'defaultMenu';
  const category = state.byId[categoryId];
  const defaultCategory = state.byId[defaultMenu];
  if (!category) {
    return undefined;
  }
  let topCategory;
  const defaultCategorySubCategories = Object.values(defaultCategory.categories);
  for (var i = 0; i < defaultCategorySubCategories.length; i++) {
    if (defaultCategorySubCategories[i].id === categoryId) {
      topCategory = defaultCategorySubCategories[i];
      return { topCategory, isASubCategory: false };
    } else {
      topCategory = checkCategoryByCategoryId(defaultCategorySubCategories[i], defaultCategorySubCategories[i], categoryId);
      if (topCategory) {
        return { topCategory, isASubCategory: true };
      }
    }
  }
};

const checkCategoryByCategoryId = (_topCategory, currentCategory, categoryId) => {
  let topCategory;
  const currentCategorySubCategories = Object.values(currentCategory.categories);
  for (var i = 0; i < currentCategorySubCategories.length; i++) {
    if (currentCategorySubCategories[i].id === categoryId) {
      topCategory = _topCategory;
      return topCategory;
    } else {
      if (currentCategorySubCategories[i].categories && Object.keys(currentCategorySubCategories[i].categories).length > 0) {
        topCategory = checkCategoryByCategoryId(_topCategory, currentCategorySubCategories[i], categoryId);
      }
    }
  }
  return topCategory;
};
