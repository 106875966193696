import { combineReducers } from 'redux';

const byRestaurantKey = (state = {}, action) => {
  const { type, restaurantKey } = action;
  switch (type) {
    case 'ADD_VALIDATED_CART': {
      const { cart } = action;

      return { ...state, [restaurantKey]: cart };
    }

    case 'LOGOUT': {
      return {};
    }
    default:
      return state;
  }
};

const validatedCarts = combineReducers({
  byRestaurantKey
});

export default validatedCarts;

export const get = (state, restaurantKey) => state.byRestaurantKey[restaurantKey] || undefined;
