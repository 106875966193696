import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import reducers from './redux/reducers';
import { loadState, saveState } from './localStorage';

const cleanState = state => {
  if (state) {
    // Do any cleanup to the existing state here.
    // This is important when upgrading with a state structure change.
    return { auth: state.auth, carts: state.carts, activeRestaurant: state.activeRestaurant };
  }
  return state;
};

const configureStore = () => {
  const middleware = [thunk];
  if (process.env.NODE_ENV !== 'production') {
    middleware.push(logger);
  }

  const store = createStore(
    reducers,
    cleanState(loadState()),
    applyMiddleware(...middleware)
  );

  store.subscribe(() => {
    saveState(store.getState());
  });

  return store;
};

export default configureStore();
