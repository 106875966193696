import { combineReducers } from 'redux';

const byRestaurantKey = (state = {}, action) => {
  const { type, restaurantKey } = action;
  switch (type) {
    case 'CLEAR_RESTAURANT_DATA': {
      return {};
    }
    case 'ADD': {
      const { filter } = action;
      return { ...state, [restaurantKey]: filter };
    }
    case 'CLEAR':
      return { ...state, [restaurantKey]: {} };
    case 'UPDATE': {
      const filter = state[restaurantKey];
      if (!filter) return state;
      // return { ...state, [restaurantKey]: { ...filter, ...action.cart } };
      return { ...state, [restaurantKey]: filter };
    }

    case 'LOGOUT': {
      // Clear filters related to specific users
      const newState = {};
      Object.keys(state).forEach(key => {
        newState[key] = defaultFilter;
      });

      return newState;
    }
    default:
      return state;
  }
};

const filters = combineReducers({
  byRestaurantKey
});

export default filters;

// export const get = (state, restaurantKey) => state.byRestaurantKey[restaurantKey] || { ...defaultCart };
export const get = (state, restaurantKey) => state.byRestaurantKey[restaurantKey] || { ...defaultFilter };

const defaultFilter = {
  text: '',
  price: {
    enabled: false,
    from: 0,
    to: 1000
  },
  servings: {
    enabled: false,
    value: 'ALL'
  }
};
