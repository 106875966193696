import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { isAuthenticated, getLoggedInUser } from './redux/reducers';
import Loader from './components/Loader';

const Home = lazy(() => import('./components/Home'));
const Merchant = lazy(() => import('./components/Merchant'));
const Auth0Callback = lazy(() => import('./auth/auth0Callback'));
const Profile = lazy(() => import('./containers/Page/Profile'));
const SignIn = lazy(() => import('./containers/Page/SignIn'));
const SignOut = lazy(() => import('./containers/Page/SignOut'));
const LatedSignIn = lazy(() => import('./containers/Page/LatedSignIn'));
const AccountInformationContainer = lazy(() => import('./containers/AccountInformation/AccountInformationContainer'));
const VerificationContainer = lazy(() => import('./containers/Verification/VerificationContainer'));
const RestaurantContainer = lazy(() => import('./containers/Restaurant/RestaurantContainer'));

class Root extends Component {
  render = () => {
    const { authenticated, user, auth } = this.props;

    const authCallbackRoute = [
      <Route key='/callback' path='/callback' exact component={Auth0Callback} />
    ];
    const publicRoutes = [
      <Route key='/redirect/camelcase' sensitive path='/:slug1*:slug2([A-Z]):slug3*/' render={props => <Redirect to={`${props.location.pathname.toLowerCase()}`} />} />,
      <Route key='/' path='/' exact component={Home} />,
      <Route key='/accountInformationContainer' path='/accountInformationContainer' exact component={AccountInformationContainer} />,
      <Route key='/merchant' path='/merchant' exact component={Merchant} />,
      <Route key='/signin' path='/signin' exact component={SignIn} />,
      <Route key='/signout' path='/signout' exact component={SignOut} />,
      <Route key='/signup' path='/signup' exact component={LatedSignIn} />,
      // <Route key='/:restaurantKey' path='/:restaurantKey' component={RstaurantRoot} />
      <Route key='/:restaurantKey' path='/:restaurantKey' component={RestaurantContainer} />
    ];
    const privateRoutes = [
      <Route key='/callback' path='/callback' render={() => <Redirect to='/' />} />,
      <Route key='/' path='/' exact component={Home} />,
      <Route key='/accountInformationContainer' path='/accountInformationContainer' exact component={AccountInformationContainer} />,
      <Route key='/profile' path='/profile' render={() => <Profile auth={auth} />} />
    ];

    const signupRoutes = [
      <Route key='/signup/profile' path='/signup/profile' component={AccountInformationContainer} />,
      <Route key='redirect/signup/profile' render={() => <Redirect to='/signup/profile' />} />
    ];

    const verificationRoutes = [
      // <Route key='/signup/verification' path='/signup/verification' exact component={SignUpVerifyAccount} />,
      <Route key='/signup/verification' path='/signup/verification' exact component={VerificationContainer} />,
      <Route key='redirect/signup/verification' render={() => <Redirect to='/signup/verification' />} />
    ];

    let routes = [...publicRoutes];

    if (authenticated) {
      if (user.consumer.name === '') {
        routes = [...signupRoutes, ...routes];
      } else if (!user.consumer.mobileVerified) {
        routes = [...verificationRoutes, ...routes];
      } else {
        routes = [...privateRoutes, ...routes];
      }
    } else {
      routes = [...authCallbackRoute, ...routes];
    }

    return (
      <Suspense fallback={<Loader isSidebar={false} />}>
        <Switch>
          {routes}
        </Switch>
      </Suspense>
    );
  }
}

const mapStateToProps = state => {
  return {
    authenticated: isAuthenticated(state),
    user: getLoggedInUser(state)
  };
};

Root.contextTypes = {
  auth: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Root);
